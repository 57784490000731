






























import recomendacionModule from "@/store/modules/recomendacion-module";
import { Component, Vue } from "vue-property-decorator";

@Component({ $_veeValidate: { validator: "new" } })
export default class Recomendaciones_dieta extends Vue {
  created() {
    recomendacionModule.getrecomendaciondieta(this.$route.params.id);
  }
  public get recomendacionesdieta() {
    return recomendacionModule.recomendacion_dieta;
  }
}
